/*
  @radix-ui/colors/violet.css
*/
:root,
.light,
.light-theme {
  /* Violet colors */
  --violet-1: #f2f2f4 !important;
  --violet-2: #ececf5 !important;
  --violet-3: #e4e4f4 !important;
  --violet-4: #d9d9f8 !important;
  --violet-5: #cdcdfa !important;
  --violet-6: #bebdf9 !important;
  --violet-7: #aaa8f2 !important;
  --violet-8: #908aeb !important;
  --violet-9: #4612b4 !important;
  --violet-10: #542fc9 !important;
  --violet-11: #5538c3 !important;
  --violet-12: #2e2369 !important;

  --violet-a1: #3636b604 !important;
  --violet-a2: #1010f50a !important;
  --violet-a3: #0505e712 !important;
  --violet-a4: #9999ff4d !important;
  --violet-a5: #a6a6ff80 !important;
  --violet-a6: #6c69ff66 !important;
  --violet-a7: #0903ec51 !important;
  --violet-a8: #1002df70 !important;
  --violet-a9: #3901afed !important;
  --violet-a10: #2f01bfcf !important;
  --violet-a11: #2600b4c5 !important;
  --violet-a12: #0d0052db !important;

  --violet-contrast: #fff !important;
  --violet-surface: #eaeaf5cc !important;
  --violet-indicator: #4612b4 !important;
  --violet-track: #4612b4 !important;

  /* Gray colors */
  --gray-1: #f1f1f2 !important;
  --gray-2: #ededf0 !important;
  --gray-3: #e3e4e7 !important;
  --gray-4: #dadbdf !important;
  --gray-5: #d2d3d9 !important;
  --gray-6: #cacbd2 !important;
  --gray-7: #bebfc8 !important;
  --gray-8: #aaacb7 !important;
  --gray-9: #7c7e89 !important;
  --gray-10: #72747e !important;
  --gray-11: #55565f !important;
  --gray-12: #1e1f24 !important;

  --gray-a1: #2a2a5d05 !important;
  --gray-a2: #13136809 !important;
  --gray-a3: #04113a13 !important;
  --gray-a4: #0811341d !important;
  --gray-a5: #040b3525 !important;
  --gray-a6: #02082f2d !important;
  --gray-a7: #0408303a !important;
  --gray-a8: #030a2d4f !important;
  --gray-a9: #01051b7e !important;
  --gray-a10: #02051889 !important;
  --gray-a11: #000210a7 !important;
  --gray-a12: #000107e0 !important;

  --gray-contrast: #fff !important;
  --gray-surface: #fffc !important;
  --gray-indicator: #7c7e89 !important;
  --gray-track: #7c7e89 !important;

  /* Background */
  --color-background: #f5f5f5 !important;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --violet-1: oklch(96.2% 0.0031 284.8deg) !important;
      --violet-2: oklch(94.7% 0.0109 284.8deg) !important;
      --violet-3: oklch(92.4% 0.021 284.8deg) !important;
      --violet-4: oklch(89.5% 0.0425 284.8deg) !important;
      --violet-5: oklch(86.2% 0.0631 284.8deg) !important;
      --violet-6: oklch(82% 0.083 284.8deg) !important;
      --violet-7: oklch(76.1% 0.1052 284.8deg) !important;
      --violet-8: oklch(68.1% 0.1403 284.8deg) !important;
      --violet-9: oklch(39.7% 0.2192 284.8deg) !important;
      --violet-10: oklch(45.7% 0.2192 284.8deg) !important;
      --violet-11: oklch(46.4% 0.2031 284.8deg) !important;
      --violet-12: oklch(31.4% 0.117 284.8deg) !important;

      --violet-a1: color(display-p3 0.0588 0.0588 0.6627 / 0.013) !important;
      --violet-a2: color(display-p3 0.0078 0.0078 0.8549 / 0.037) !important;
      --violet-a3: color(display-p3 0.0118 0.0118 0.851 / 0.07) !important;
      --violet-a4: color(display-p3 0.0078 0.0078 0.9647 / 0.115) !important;
      --violet-a5: color(display-p3 0 0 0.9961 / 0.1) !important;
      --violet-a6: color(display-p3 0.0196 0.0039 0.9451 / 0.229) !important;
      --violet-a7: color(display-p3 0.0275 0.0039 0.8627 / 0.315) !important;
      --violet-a8: color(display-p3 0.0471 0.0039 0.8118 / 0.437) !important;
      --violet-a9: color(display-p3 0.1804 0 0.651 / 0.911) !important;
      --violet-a10: color(display-p3 0.149 0 0.7059 / 0.8) !important;
      --violet-a11: color(display-p3 0.1216 0 0.6706 / 0.768) !important;
      --violet-a12: color(display-p3 0.0431 0 0.302 / 0.858) !important;

      --violet-contrast: #fff !important;
      --violet-surface: color(display-p3 0.9176 0.9216 0.9569 / 0.8) !important;
      --violet-indicator: oklch(39.7% 0.2192 284.8deg) !important;
      --violet-track: oklch(39.7% 0.2192 284.8deg) !important;

      --gray-1: oklch(95.9% 0.0015 277.7deg) !important;
      --gray-2: oklch(94.8% 0.003 277.7deg) !important;
      --gray-3: oklch(91.8% 0.0045 277.7deg) !important;
      --gray-4: oklch(89.2% 0.0061 277.7deg) !important;
      --gray-5: oklch(86.8% 0.0077 277.7deg) !important;
      --gray-6: oklch(84.3% 0.0093 277.7deg) !important;
      --gray-7: oklch(80.7% 0.0117 277.7deg) !important;
      --gray-8: oklch(74.6% 0.016 277.7deg) !important;
      --gray-9: oklch(59.6% 0.0165 277.7deg) !important;
      --gray-10: oklch(56% 0.0161 277.7deg) !important;
      --gray-11: oklch(45.6% 0.0139 277.7deg) !important;
      --gray-12: oklch(24.1% 0.0099 277.7deg) !important;

      --gray-a1: color(display-p3 0.0392 0.0392 0.2706 / 0.017) !important;
      --gray-a2: color(display-p3 0.0118 0.0118 0.3686 / 0.033) !important;
      --gray-a3: color(display-p3 0.0078 0.0627 0.2196 / 0.074) !important;
      --gray-a4: color(display-p3 0.0078 0.0431 0.1843 / 0.111) !important;
      --gray-a5: color(display-p3 0.0039 0.0314 0.1686 / 0.143) !important;
      --gray-a6: color(display-p3 0.0039 0.0275 0.1608 / 0.176) !important;
      --gray-a7: color(display-p3 0.0039 0.0196 0.1608 / 0.225) !important;
      --gray-a8: color(display-p3 0.0039 0.0314 0.1569 / 0.307) !important;
      --gray-a9: color(display-p3 0.0039 0.0196 0.098 / 0.494) !important;
      --gray-a10: color(display-p3 0 0.0157 0.0824 / 0.535) !important;
      --gray-a11: color(display-p3 0 0.0078 0.0549 / 0.654) !important;
      --gray-a12: color(display-p3 0 0.0039 0.0275 / 0.878) !important;

      --gray-contrast: #fff !important;
      --gray-surface: color(display-p3 1 1 1 / 80%) !important;
      --gray-indicator: oklch(59.6% 0.0165 277.7deg) !important;
      --gray-track: oklch(59.6% 0.0165 277.7deg) !important;
    }
  }
}

/* Dark */

.dark,
.dark-theme {
  /* Violet colors */
  --violet-1: #0f0e23 !important;
  --violet-2: #151230 !important;
  --violet-3: #251064 !important;
  --violet-4: #340090 !important;
  --violet-5: #3d00a4 !important;
  --violet-6: #4613b5 !important;
  --violet-7: #5321ce !important;
  --violet-8: #6326f5 !important;
  --violet-9: #4612b4 !important;
  --violet-10: #5526d1 !important;
  --violet-11: #ada8ff !important;
  --violet-12: #ddf !important;

  --violet-a1: #0000f714 !important;
  --violet-a2: #2f19fa22 !important;
  --violet-a3: #4b0fff59 !important;
  --violet-a4: #5200fe89 !important;
  --violet-a5: #5900ff9e !important;
  --violet-a6: #5e14ffb0 !important;
  --violet-a7: #6426ffcb !important;
  --violet-a8: #6627fef5 !important;
  --violet-a9: #5f13ffaf !important;
  --violet-a10: #662bffce !important;
  --violet-a11: #ada8ff !important;
  --violet-a12: #ddf !important;

  --violet-contrast: #fff !important;
  --violet-surface: #19134f80 !important;
  --violet-indicator: #4612b4 !important;
  --violet-track: #4612b4 !important;

  /* Gray colors */
  --gray-1: #111113 !important;
  --gray-2: #19191b !important;
  --gray-3: #222325 !important;
  --gray-4: #292a2e !important;
  --gray-5: #303136 !important;
  --gray-6: #393a40 !important;
  --gray-7: #46484f !important;
  --gray-8: #5f606a !important;
  --gray-9: #6c6e79 !important;
  --gray-10: #797b86 !important;
  --gray-11: #b2b3bd !important;
  --gray-12: #eeeef0 !important;

  --gray-a1: #1111bb03 !important;
  --gray-a2: #cbcbf90b !important;
  --gray-a3: #d6e2f916 !important;
  --gray-a4: #d1d9f920 !important;
  --gray-a5: #d7ddfd28 !important;
  --gray-a6: #d9defc33 !important;
  --gray-a7: #dae2fd43 !important;
  --gray-a8: #e0e3fd60 !important;
  --gray-a9: #e0e4fd70 !important;
  --gray-a10: #e3e7fd7e !important;
  --gray-a11: #eff0feb9 !important;
  --gray-a12: #fdfdffef !important;

  --gray-contrast: #fff !important;
  --gray-surface: rgba(0, 0, 0, 0.05) !important;
  --gray-indicator: #6c6e79 !important;
  --gray-track: #6c6e79 !important;

  /* Background */
  --color-background: #111 !important;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark,
    .dark-theme {
      --violet-1: oklch(17.8% 0.0424 284.8deg) !important;
      --violet-2: oklch(20.6% 0.0574 284.8deg) !important;
      --violet-3: oklch(27.2% 0.1346 284.8deg) !important;
      --violet-4: oklch(31.8% 0.2 284.8deg) !important;
      --violet-5: oklch(35.8% 0.2144 284.8deg) !important;
      --violet-6: oklch(39.9% 0.2192 284.8deg) !important;
      --violet-7: oklch(44.8% 0.2356 284.8deg) !important;
      --violet-8: oklch(50.7% 0.2709 284.8deg) !important;
      --violet-9: oklch(39.7% 0.2192 284.8deg) !important;
      --violet-10: oklch(45.7% 0.2356 284.8deg) !important;
      --violet-11: oklch(77.6% 0.2572 284.8deg) !important;
      --violet-12: oklch(91.4% 0.0822 284.8deg) !important;

      --violet-a1: color(display-p3 0 0 0.9961 / 0.072) !important;
      --violet-a2: color(display-p3 0.1647 0.102 1 / 0.122) !important;
      --violet-a3: color(display-p3 0.2706 0.0706 1 / 0.332) !important;
      --violet-a4: color(display-p3 0.298 0 1 / 0.509) !important;
      --violet-a5: color(display-p3 0.3216 0 1 / 0.589) !important;
      --violet-a6: color(display-p3 0.3451 0.102 1 / 0.66) !important;
      --violet-a7: color(display-p3 0.3765 0.1647 1 / 0.761) !important;
      --violet-a8: color(display-p3 0.3882 0.1765 1 / 0.921) !important;
      --violet-a9: color(display-p3 0.349 0.098 1 / 0.656) !important;
      --violet-a10: color(display-p3 0.3804 0.1882 1 / 0.774) !important;
      --violet-a11: color(display-p3 0.6902 0.6745 1 / 0.975) !important;
      --violet-a12: color(display-p3 0.8784 0.8784 1 / 0.988) !important;

      --violet-contrast: #fff !important;
      --violet-surface: color(display-p3 0.0941 0.0706 0.2902 / 0.5) !important;
      --violet-indicator: oklch(39.7% 0.2192 284.8deg) !important;
      --violet-track: oklch(39.7% 0.2192 284.8deg) !important;

      --gray-1: oklch(17.8% 0.0042 277.7deg) !important;
      --gray-2: oklch(21.5% 0.004 277.7deg) !important;
      --gray-3: oklch(25.5% 0.0055 277.7deg) !important;
      --gray-4: oklch(28.4% 0.0075 277.7deg) !important;
      --gray-5: oklch(31.4% 0.0089 277.7deg) !important;
      --gray-6: oklch(35% 0.01 277.7deg) !important;
      --gray-7: oklch(40.2% 0.0121 277.7deg) !important;
      --gray-8: oklch(49.2% 0.0157 277.7deg) !important;
      --gray-9: oklch(54% 0.0167 277.7deg) !important;
      --gray-10: oklch(58.6% 0.0165 277.7deg) !important;
      --gray-11: oklch(77% 0.0138 277.7deg) !important;
      --gray-12: oklch(94.9% 0.0026 277.7deg) !important;

      --gray-a1: color(display-p3 0.0667 0.0667 0.9412 / 0.009) !important;
      --gray-a2: color(display-p3 0.8 0.8 0.9804 / 0.043) !important;
      --gray-a3: color(display-p3 0.851 0.898 0.9882 / 0.085) !important;
      --gray-a4: color(display-p3 0.8392 0.8706 1 / 0.122) !important;
      --gray-a5: color(display-p3 0.8471 0.8745 1 / 0.156) !important;
      --gray-a6: color(display-p3 0.8784 0.898 1 / 0.194) !important;
      --gray-a7: color(display-p3 0.8745 0.9059 0.9961 / 0.257) !important;
      --gray-a8: color(display-p3 0.8941 0.9059 1 / 0.37) !important;
      --gray-a9: color(display-p3 0.8902 0.9098 1 / 0.433) !important;
      --gray-a10: color(display-p3 0.902 0.9176 1 / 0.488) !important;
      --gray-a11: color(display-p3 0.9451 0.949 1 / 0.719) !important;
      --gray-a12: color(display-p3 0.9922 0.9922 1 / 0.937) !important;

      --gray-contrast: #fff;
      --gray-surface: color(display-p3 0 0 0 / 5%);
      --gray-indicator: oklch(54% 0.0167 277.7deg);
      --gray-track: oklch(54% 0.0167 277.7deg);
    }
  }
}

/* Background */

.dark,
.dark-theme,
:is(.dark, .dark-theme) :where(.radix-themes:not(.light, .light-theme)) {
  --color-background: #111 !important;
}
